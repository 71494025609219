import { StyledForm, StyledInput } from "./summary-page-card.styles";
import { Button } from "components/atoms/button/button";
import { FormEventHandler } from "react";

const PromoCodeInput = ({
  onSubmit,
  valid_coupon,
  defaultValue,
  value,
  setValue
}: {
  onSubmit: FormEventHandler<HTMLFormElement>;
  valid_coupon: boolean | undefined;
  defaultValue: string | string[] | undefined;
  value: string | number | undefined;
  setValue: React.Dispatch<React.SetStateAction<string | number | undefined>>;
}): JSX.Element => {
  return (
    <StyledForm valid_coupon={valid_coupon} onSubmit={onSubmit}>
      <StyledInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        placeholder="Memasukkan kode"
        value={value}
        defaultValue={defaultValue}
        type="text"
      />
      <Button type="submit" variant="ghost">
        Pakai
      </Button>
    </StyledForm>
  );
};

export { PromoCodeInput };
