import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import trackEvent from "services/mixpanel/mixpanel";
import { webCheckoutEventNames } from "services/mixpanel/web-checkout-events";
import {
  ICommonPageFunctionProps,
  ICouponEventsTrackingProps,
  IVideoPageTrackingData
} from "./utils.types";

export const onChangeTenureClickForPages = ({
  eventName,
  userData,
  packageId,
  tenureId,
  packageSummaryData,
  stage
}: ICommonPageFunctionProps): void => {
  const payload = createPayloadForWebCheckoutTrackingEvent({
    useAuthData: userData,
    Class: true,
    level: true,
    packageType:
      packageId && packageSummaryData ? packageSummaryData.data.name : null,
    tenureValue:
      tenureId && packageSummaryData
        ? packageSummaryData.data.tenure_details.tenure_text.text
        : null,
    stage
  });
  trackEvent({
    eventName,
    payload
  });
};

export const onClickContinueTenurePopupForPages = ({
  eventName,
  userData,
  packageId,
  tenureId,
  packageSummaryData,
  stage
}: ICommonPageFunctionProps): void => {
  const payload = createPayloadForWebCheckoutTrackingEvent({
    useAuthData: userData,
    Class: true,
    level: true,
    packageType:
      packageId && packageSummaryData ? packageSummaryData.data.name : null,
    tenureValue:
      tenureId && packageSummaryData
        ? packageSummaryData.data.tenure_details.tenure_text.text
        : null,
    stage,
    userFlow: true
  });
  trackEvent({
    eventName: eventName,
    payload
  });
};

export const onCloseTenureChangeModalForPages = ({
  eventName,
  userData,
  packageId,
  tenureId,
  packageSummaryData,
  stage
}: ICommonPageFunctionProps): void => {
  const payload = createPayloadForWebCheckoutTrackingEvent({
    useAuthData: userData,
    Class: true,
    level: true,
    packageType:
      packageId && packageSummaryData ? packageSummaryData.data.name : null,
    tenureValue:
      tenureId && packageSummaryData
        ? packageSummaryData.data.tenure_details.tenure_text.text
        : null,
    stage,
    userFlow: true
  });
  trackEvent({
    eventName,
    payload
  });
};

export const couponEventsTracking = ({
  coupon_code,
  packageId,
  tenureId,
  packageSummaryData,
  data,
  user,
  stage
}: ICouponEventsTrackingProps): void => {
  const payloadForCouponEvents = createPayloadForWebCheckoutTrackingEvent({
    useAuthData: user,
    Class: true,
    level: true,
    packageType: packageId ? data.name : null,
    tenureValue: tenureId ? data.tenure_details.tenure_text.text : null,
    stage,
    couponCodeName: String(coupon_code)
  });

  if (packageSummaryData.data?.tenure_details.valid_coupon) {
    trackEvent({
      eventName: webCheckoutEventNames.couponCodeSuccess,
      payload: payloadForCouponEvents
    });
  } else {
    trackEvent({
      eventName: webCheckoutEventNames.invalidCouponCode,
      payload: payloadForCouponEvents
    });
  }
};

export const videoPageTrackingData = ({
  videoData,
  searchQuery,
  videoSearchIndex,
  utm_source,
  utm_medium,
  sharedBy,
  recommended,
  rank
}: IVideoPageTrackingData) => ({
  incomingSource: "website",
  ...(searchQuery && { searchQuery }),
  questionId: videoData.questionId,
  questionText: videoData.title,
  subject: [videoData.subjects[0]],
  questionSection: videoData.sections,
  questionChapter: videoData.chapters,
  questionTopic: videoData.topics,
  grade: [videoData.grades[0]],
  ...(videoSearchIndex && { videoSearchIndex }),
  ...(utm_source && { utm_source }),
  ...(utm_medium && { utm_medium }),
  ...(sharedBy && { sharedBy }),
  isShared: sharedBy ? true : false,
  tanyaRecommendedVideo: recommended || "false",
  ...(rank && { recommendedVideoRank: rank })
});
