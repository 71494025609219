import { useRouter } from "next/router";
import { useState, useEffect, FormEvent } from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { webCheckoutEventNames } from "services/mixpanel/web-checkout-events";
import { useAuth } from "stores/auth/auth-context";
import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import {
  IUseSummaryCardProps,
  IUseSummaryPageCard
} from "./summary-page-card.types";

export const useSummaryPageCard = ({
  base_price,
  total_amount,
  packageType,
  tenureValue
}: IUseSummaryCardProps): IUseSummaryPageCard => {
  const isDiscountPercentageGreaterThanZero = base_price > total_amount;
  const { user } = useAuth();
  const router = useRouter();
  const { coupon_code = "" } = router.query;

  const [isPromoCodeSelected, setIsPromoCodeSelected] = useState(false);
  const [promoCode, setPromoCode] = useState<string | number | undefined>(
    coupon_code as string
  );

  let stage: string | null;
  if (
    router.asPath.includes("add-parent") ||
    router.asPath.includes("add-user")
  ) {
    stage = "Create Profile";
  } else if (router.asPath.includes("summary")) {
    stage = "Payment Summary";
  } else if (router.asPath.includes("select-role")) {
    stage = "User Type";
  } else {
    stage = null;
  }

  const onClickHavePromoCode = (): void => {
    setIsPromoCodeSelected(true);
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user,
      packageType: packageType || null,
      tenureValue: tenureValue || null,
      Class: true,
      level: true,
      stage
    });
    trackEvent({
      eventName: webCheckoutEventNames.haveCouponCodeClicked,
      payload
    });
  };

  useEffect(() => {
    if (coupon_code) {
      setIsPromoCodeSelected(true);
    }
  }, [coupon_code]);

  const handleCouponCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        coupon_code: promoCode
      }
    });

    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user,
      packageType: packageType || null,
      tenureValue: tenureValue || null,
      Class: true,
      level: true,
      stage
    });
    trackEvent({
      eventName: webCheckoutEventNames.applyCouponCodeClicked,
      payload
    });
  };

  const removePromoCode = () => {
    router.push({
      pathname: router.pathname,
      query: {
        package: router.query.package,
        tenure: router.query.tenure
      }
    });
  };

  return {
    handleCouponCodeSubmit,
    isPromoCodeSelected,
    setIsPromoCodeSelected,
    coupon_code,
    promoCode,
    setPromoCode,
    isDiscountPercentageGreaterThanZero,
    removePromoCode,
    onClickHavePromoCode
  };
};
