import { useEffect, useState } from "react";
import { getPackageAndTenureSummaryDetails } from "services/api";
import { useAuth } from "stores/auth/auth-context";
import { IPackageAndTenureSummaryDetailsResponse } from "services/api/packages/packageTenureSummaryDetails.types";
import {
  IUseGetPackageAndTenureSummary,
  IUseGetPackageAndTenureSummaryProps
} from "./usePackageAndTenureSummaryDetails.types";
import * as Sentry from "@sentry/nextjs";

export const usePackageAndTenureSummaryDetails = ({
  router
}: IUseGetPackageAndTenureSummaryProps): IUseGetPackageAndTenureSummary => {
  const { user } = useAuth();
  const { userData } = user;

  const {
    package: packageId,
    tenure: tenureId,
    coupon_code: coupon_code
  } = router.query;

  const [packageSummaryData, setPackageSummaryData] =
    useState<IPackageAndTenureSummaryDetailsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    async function fetchSummaryData() {
      try {
        const response = await getPackageAndTenureSummaryDetails({
          packageId: String(packageId),
          tenureId: String(tenureId),
          coupon_code: String(coupon_code || ""),
          user_id: userData?.primary_user?.id || ""
        });
        setPackageSummaryData(response);
        setIsLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        router.push({ pathname: "/404" });
      }
    }

    if (router.isReady && packageId && tenureId) {
      fetchSummaryData();
    }
  }, [
    router.isReady,
    tenureId,
    coupon_code,
    packageId,
    userData?.primary_user
  ]);

  return { packageSummaryData, isLoading, packageId, tenureId, coupon_code };
};
