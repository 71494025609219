import styled from "styled-components";
import { breakpoints } from "styles";
import { Form } from "components/form-component/form/form";

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 30px ${({ theme }) => theme.colors.transparent.white10};
  border-radius: 10px;
  padding: 24px 16px;
  gap: 20px;
  @media ${breakpoints.tablet} {
    padding: 32px;
    width: 380px;
  }
`;

export const OrderDetails = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }
`;

export const SubjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
  flex: 1;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral["100"]}`};
  border-radius: 8px;
  @media ${breakpoints.tablet} {
    width: 355px;
  }
`;

export const AmbitiousPackage = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
`;

export const Subjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0;
  margin-right: 0;
  @media ${breakpoints.tablet} {
    width: 280px;
  }
`;

export const ValidityPeriodAndSemesterContainer = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${breakpoints.tablet} {
    gap: 4px;
  }
`;

export const ValidityPeriod = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
  }
`;

export const SemesterAndChangeContainer = styled.ul`
  margin-top: 4px;
  padding: 0;
`;

export const Semester = styled.li`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  font-style: normal;
  font-weight: bold;
  margin: 0 16px;
  margin-top: 4px;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;
export const BonusSemester = styled(Semester)`
  color: ${({ theme }) => theme.colors.green[800]};
`;
export const BonusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const FreeTag = styled.span`
  border-radius: 2px;
  padding: 2px 4px;
  line-height: 16px;
  background-color: ${({ theme }) => theme.colors.green[800]};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  @media ${breakpoints.tablet} {
    padding: 4px 6px 3px 6px;
    border-radius: 4px;
  }
`;
export const ChangeSemesterCTA = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.brand[500]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const StudyPeriodContainer = styled(ValidityPeriodAndSemesterContainer)`
  flex-direction: column;
  align-items: flex-start;
`;
export const PromoMessage = styled.div`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.green[100]};
  color: ${({ theme }) => theme.colors.green[800]};
  border-radius: 6px;
  line-height: 24px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;
export const StudyPeriod = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
  }
`;

export const OrderDate = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
  @media ${breakpoints.tablet} {
    width: 230px;
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;

export const HorizontalRule = styled.hr`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral[100]}`};
`;

export const PriceAndDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const PriceAndValidityContainer = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.tablet} {
    width: 380px;
  }
`;

export const Validity = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
`;

export const Price = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
`;

export const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.tablet} {
    width: 380px;
  }
`;

export const DiscountText = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  @media ${breakpoints.tablet} {
    width: auto;
  }
`;

export const Discount = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  color: ${({ theme }) => theme.colors.green["600"]};
  @media ${breakpoints.tablet} {
    width: auto;
  }
`;

export const DiscountedAmountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media ${breakpoints.tablet} {
    margin: 0;
  }
`;

export const Total = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  margin: 0;
`;

export const AmountPayableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Amount = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  margin: 0;
  text-align: right;
`;

export const AmountSaved = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.neutral["600"]};
  margin: 8px 0 0 0;
`;

export const VATDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.neutral["600"]};
  margin: 8px 0 100px 0;

  @media ${breakpoints.tablet} {
    margin-bottom: 10px;
  }
`;

export const PromoCodeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const PromoCodeTitle = styled.p`
  color: ${({ theme }) => theme.colors.brand["500"]};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
`;

export const OptionalText = styled.p`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const PromoCodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled(Form)<{ valid_coupon: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  border: 2px solid
    ${({ theme, valid_coupon }) =>
      valid_coupon === false
        ? theme.colors.red["100"]
        : theme.colors.brand["500"]};
  border-radius: 5px;
  padding: 2px 4px 2px 10px;
`;

export const StyledInput = styled.input`
  border: none;
  flex-grow: 2;

  &:focus {
    outline: none;
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.red["100"]};
`;

export const DiscountTag = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.green["600"]};
  color: ${({ theme }) => theme.colors.green["600"]};
  background: ${({ theme }) => theme.colors.green["100"]};
  text-transform: capitalize;
  width: fit-content;
  padding: 8px;
  border-style: dashed;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-weight: bold;
`;

export const CrossIconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.green["600"]};
`;

export const DetailsFlex = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: column;
  gap: 20px;
  @media ${breakpoints.tablet} {
    display: flex;
  }
`;
