import React from "react";
import {
  ChangeSemesterCTA,
  OrderDetails,
  Semester,
  SemesterAndChangeContainer,
  StudyPeriod,
  OrderDate,
  StudyPeriodContainer,
  SummaryCard,
  ValidityPeriod,
  ValidityPeriodAndSemesterContainer,
  HorizontalRule,
  PriceAndDiscountContainer,
  PriceAndValidityContainer,
  Validity,
  Price,
  DiscountText,
  Discount,
  DiscountContainer,
  DiscountedAmountContainer,
  Total,
  AmountPayableContainer,
  Amount,
  AmountSaved,
  SubjectsContainer,
  AmbitiousPackage,
  Subjects,
  PromoCodeContainer,
  PromoCodeTitle,
  OptionalText,
  PromoCodeInputContainer,
  ErrorText,
  DiscountTag,
  CrossIconWrapper,
  DetailsFlex,
  BonusSemester,
  FreeTag,
  BonusContainer,
  PromoMessage,
  VATDescription
} from "./summary-page-card.styles";
import { ISummaryPageData } from "./summary-page-card.types";
import { currencyDelimeter, getMonthAndYear } from "../utils";
import { calculateDiscountPercentage } from "utils/utils";
import { SubjectTag } from "components/subject-tag/subject-tag";
import { PromoCodeInput } from "./promo-code-input";
import { useSummaryPageCard } from "./use-summary-page-card";
import { CrossIcon } from "components/icons/cross-icon";

const SummaryPageCard: React.FC<ISummaryPageData> = ({
  subjects,
  tenure_ends_at,
  tenure_starts_at,
  base_price,
  list_price,
  discount_amount,
  total_amount,
  changeTenureAction,
  tenure_text,
  showPriceBreakdown = true,
  showPackageDetails = true,
  mediaUrl,
  name,
  valid_coupon
}) => {
  const {
    isDiscountPercentageGreaterThanZero,
    handleCouponCodeSubmit,
    isPromoCodeSelected,
    coupon_code,
    setPromoCode,
    promoCode,
    removePromoCode,
    onClickHavePromoCode
  } = useSummaryPageCard({
    base_price,
    total_amount,
    packageType: name,
    tenureValue: tenure_text.text
  });

  return (
    <SummaryCard>
      <OrderDetails>
        {showPackageDetails ? "Detail Pesanan" : "Detail pembayaran"}
      </OrderDetails>

      <DetailsFlex isVisible={showPackageDetails}>
        <SubjectsContainer>
          <AmbitiousPackage>{name}</AmbitiousPackage>
          <Subjects>
            {subjects &&
              subjects.map((subject) => {
                return (
                  <SubjectTag
                    key={subject.id}
                    backgroundColor={subject.meta.background_colour}
                    iconSrc={`${mediaUrl}/${subject.meta.icon_small}`}
                    text={subject.name}
                    color={subject.meta.text_colour}></SubjectTag>
                );
              })}
          </Subjects>
        </SubjectsContainer>
        <div>
          <ValidityPeriodAndSemesterContainer>
            <ValidityPeriod>Periode Belajar</ValidityPeriod>
            <ChangeSemesterCTA onClick={() => changeTenureAction()}>
              Ganti
            </ChangeSemesterCTA>
          </ValidityPeriodAndSemesterContainer>
          <SemesterAndChangeContainer>
            {tenure_text.bonus_text && (
              <BonusSemester>
                <BonusContainer>
                  {tenure_text.bonus_text} <FreeTag>GRATIS</FreeTag>
                </BonusContainer>
              </BonusSemester>
            )}
            <Semester>{tenure_text.text}</Semester>
          </SemesterAndChangeContainer>
          {tenure_text.bonus_text && (
            <PromoMessage>
              Asiiik, belajarnya bisa mulai dari bulan ini, gratis!
            </PromoMessage>
          )}
        </div>
        <StudyPeriodContainer>
          <StudyPeriod>Masa Berlaku</StudyPeriod>
          <OrderDate>
            {`${getMonthAndYear(tenure_starts_at)} - ${getMonthAndYear(
              tenure_ends_at
            )}`}
          </OrderDate>
        </StudyPeriodContainer>
      </DetailsFlex>

      <DetailsFlex isVisible={showPriceBreakdown}>
        {!showPriceBreakdown && <HorizontalRule />}

        <PriceAndDiscountContainer>
          <PriceAndValidityContainer>
            <Validity>Harga</Validity>
            <Price>Rp{currencyDelimeter(base_price)}</Price>
          </PriceAndValidityContainer>
          {isDiscountPercentageGreaterThanZero && (
            <DiscountContainer>
              <DiscountText>
                Diskon ({calculateDiscountPercentage(base_price, list_price)}
                %)
              </DiscountText>
              <Discount>
                - Rp{currencyDelimeter(Number(base_price - list_price))}
              </Discount>
            </DiscountContainer>
          )}

          {valid_coupon ? (
            <>
              <DiscountContainer>
                <DiscountText>Kodo Promo (Opsional)</DiscountText>

                <Discount>
                  - Rp{currencyDelimeter(Number(discount_amount))}
                </Discount>
              </DiscountContainer>

              <DiscountTag>
                {coupon_code}{" "}
                <CrossIconWrapper onClick={removePromoCode}>
                  <CrossIcon height={12} />
                </CrossIconWrapper>
              </DiscountTag>
            </>
          ) : (
            <>
              {!isPromoCodeSelected && (
                <PromoCodeContainer>
                  <>
                    <PromoCodeTitle onClick={() => onClickHavePromoCode()}>
                      Punya kode promo?
                    </PromoCodeTitle>
                    <OptionalText> (opsional)</OptionalText>
                  </>
                </PromoCodeContainer>
              )}

              {isPromoCodeSelected && (
                <PromoCodeInputContainer>
                  <DiscountText>Kode Promo (Opsional)</DiscountText>

                  <PromoCodeInput
                    valid_coupon={valid_coupon}
                    defaultValue={coupon_code}
                    value={promoCode}
                    setValue={setPromoCode}
                    onSubmit={handleCouponCodeSubmit}
                  />
                </PromoCodeInputContainer>
              )}
            </>
          )}

          {coupon_code && valid_coupon === false && (
            <ErrorText>
              kode tidak valid, Silakan berbicara dengan kami
            </ErrorText>
          )}
        </PriceAndDiscountContainer>
        <HorizontalRule />
        <DiscountedAmountContainer>
          <Total>Total</Total>
          <AmountPayableContainer>
            <Amount>Rp{currencyDelimeter(total_amount)}</Amount>
            {isDiscountPercentageGreaterThanZero && (
              <AmountSaved>
                Hore, hemat - Rp
                {currencyDelimeter(Number(total_amount - base_price))}
              </AmountSaved>
            )}
          </AmountPayableContainer>
        </DiscountedAmountContainer>
        <VATDescription>
          *) Harga total yang tertera di atas sudah termasuk PPN 0%
        </VATDescription>
      </DetailsFlex>
    </SummaryCard>
  );
};

export { SummaryPageCard };
